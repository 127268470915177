import { default as enregistrementE2Xgc6rQ5bMeta } from "/app/packages/frontend-usagers/src/pages/connexion/enregistrement.vue?macro=true";
import { default as indexSugpcCziTUMeta } from "/app/packages/frontend-usagers/src/pages/connexion/index.vue?macro=true";
import { default as mot_45de_45passe_45oublie0104MfHsq0Meta } from "/app/packages/frontend-usagers/src/pages/connexion/mot-de-passe-oublie.vue?macro=true";
import { default as reset_45mot_45de_45passeAtVSUUcxq8Meta } from "/app/packages/frontend-usagers/src/pages/connexion/reset-mot-de-passe.vue?macro=true";
import { default as validationKApJUTWcQFMeta } from "/app/packages/frontend-usagers/src/pages/connexion/validation.vue?macro=true";
import { default as _91_91declarationId_93_93NuLkPBh9IsMeta } from "/app/packages/frontend-usagers/src/pages/demande-sejour/[[declarationId]].vue?macro=true";
import { default as listeZF8JLgqUdGMeta } from "/app/packages/frontend-usagers/src/pages/demande-sejour/liste.vue?macro=true";
import { default as _91_91eigId_93_938zNCcjuzliMeta } from "/app/packages/frontend-usagers/src/pages/eig/[[eigId]].vue?macro=true";
import { default as listezkVEZnd8q1Meta } from "/app/packages/frontend-usagers/src/pages/eig/liste.vue?macro=true";
import { default as accessibiliteGAuuNlB0OpMeta } from "/app/packages/frontend-usagers/src/pages/footer/accessibilite.vue?macro=true";
import { default as donnees_45personnellesZzFTwzfgAqMeta } from "/app/packages/frontend-usagers/src/pages/footer/donnees-personnelles.vue?macro=true";
import { default as gestion_45cookies3uNfiD2z9qMeta } from "/app/packages/frontend-usagers/src/pages/footer/gestion-cookies.vue?macro=true";
import { default as mentions_45legalesmTcO2DT61dMeta } from "/app/packages/frontend-usagers/src/pages/footer/mentions-legales.vue?macro=true";
import { default as _91_91hebergementId_93_93LGEAMbctRoMeta } from "/app/packages/frontend-usagers/src/pages/hebergements/[[hebergementId]].vue?macro=true";
import { default as liste7Le5t75KEZMeta } from "/app/packages/frontend-usagers/src/pages/hebergements/liste.vue?macro=true";
import { default as index7OgtSB7TtUMeta } from "/app/packages/frontend-usagers/src/pages/index.vue?macro=true";
import { default as index2djzcTkLiEMeta } from "/app/packages/frontend-usagers/src/pages/messagerie/index.vue?macro=true";
import { default as _91_91organismeId_93_93vvvqvKRyZDMeta } from "/app/packages/frontend-usagers/src/pages/organisme/[[organismeId]].vue?macro=true";
export default [
  {
    name: "connexion-enregistrement",
    path: "/connexion/enregistrement",
    component: () => import("/app/packages/frontend-usagers/src/pages/connexion/enregistrement.vue").then(m => m.default || m)
  },
  {
    name: "connexion",
    path: "/connexion",
    component: () => import("/app/packages/frontend-usagers/src/pages/connexion/index.vue").then(m => m.default || m)
  },
  {
    name: "connexion-mot-de-passe-oublie",
    path: "/connexion/mot-de-passe-oublie",
    component: () => import("/app/packages/frontend-usagers/src/pages/connexion/mot-de-passe-oublie.vue").then(m => m.default || m)
  },
  {
    name: "connexion-reset-mot-de-passe",
    path: "/connexion/reset-mot-de-passe",
    component: () => import("/app/packages/frontend-usagers/src/pages/connexion/reset-mot-de-passe.vue").then(m => m.default || m)
  },
  {
    name: "connexion-validation",
    path: "/connexion/validation",
    component: () => import("/app/packages/frontend-usagers/src/pages/connexion/validation.vue").then(m => m.default || m)
  },
  {
    name: "demande-sejour-declarationId",
    path: "/demande-sejour/:declarationId?",
    meta: _91_91declarationId_93_93NuLkPBh9IsMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/demande-sejour/[[declarationId]].vue").then(m => m.default || m)
  },
  {
    name: "demande-sejour-liste",
    path: "/demande-sejour/liste",
    component: () => import("/app/packages/frontend-usagers/src/pages/demande-sejour/liste.vue").then(m => m.default || m)
  },
  {
    name: "eig-eigId",
    path: "/eig/:eigId?",
    meta: _91_91eigId_93_938zNCcjuzliMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/eig/[[eigId]].vue").then(m => m.default || m)
  },
  {
    name: "eig-liste",
    path: "/eig/liste",
    component: () => import("/app/packages/frontend-usagers/src/pages/eig/liste.vue").then(m => m.default || m)
  },
  {
    name: "footer-accessibilite",
    path: "/footer/accessibilite",
    component: () => import("/app/packages/frontend-usagers/src/pages/footer/accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "footer-donnees-personnelles",
    path: "/footer/donnees-personnelles",
    component: () => import("/app/packages/frontend-usagers/src/pages/footer/donnees-personnelles.vue").then(m => m.default || m)
  },
  {
    name: "footer-gestion-cookies",
    path: "/footer/gestion-cookies",
    component: () => import("/app/packages/frontend-usagers/src/pages/footer/gestion-cookies.vue").then(m => m.default || m)
  },
  {
    name: "footer-mentions-legales",
    path: "/footer/mentions-legales",
    component: () => import("/app/packages/frontend-usagers/src/pages/footer/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "hebergements-hebergementId",
    path: "/hebergements/:hebergementId?",
    meta: _91_91hebergementId_93_93LGEAMbctRoMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/hebergements/[[hebergementId]].vue").then(m => m.default || m)
  },
  {
    name: "hebergements-liste",
    path: "/hebergements/liste",
    component: () => import("/app/packages/frontend-usagers/src/pages/hebergements/liste.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index7OgtSB7TtUMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "messagerie",
    path: "/messagerie",
    meta: index2djzcTkLiEMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/messagerie/index.vue").then(m => m.default || m)
  },
  {
    name: "organisme-organismeId",
    path: "/organisme/:organismeId?",
    meta: _91_91organismeId_93_93vvvqvKRyZDMeta || {},
    component: () => import("/app/packages/frontend-usagers/src/pages/organisme/[[organismeId]].vue").then(m => m.default || m)
  }
]